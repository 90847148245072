<template>
  <div>
    <b-input-group
      :class="error ? 'is-invalid' : null"
      class="input-group-merge"
    >
      <BFormInput
        class="password"
        v-bind="$props"
        :type="passwordFieldType"
        placeholder="••••••••"
        :state="error ? false : null"
        v-on="$listeners"
      />
      <b-input-group-append is-text>
        <feather-icon
          :icon="passwordToggleIcon"
          class="cursor-pointer"
          @click="togglePasswordVisibility"
        />
      </b-input-group-append>
    </b-input-group>
    <small
      v-if="error"
      class="text-danger"
    >
      {{ error }}
    </small>
  </div>
</template>

<script>
import { BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';

export default {
  name: 'InputPassword',
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  extends: BFormInput,
  props: {
    error: String,
  },
  setup() {
    const passwordFieldType = ref('password');

    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    const passwordToggleIcon = computed(
      () => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'),
    );

    return {
      passwordFieldType,
      togglePasswordVisibility,
      passwordToggleIcon,
    };
  },
};
</script>
