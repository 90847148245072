<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <div class="brand-logo">
        <Logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="
              skin === 'dark'
                ? require('@/views/auth/signIn/assets/illustration-dark.svg')
                : require('@/views/auth/signIn/assets/illustration.svg')
            "
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('resetPassword.title') }}
          </b-card-title>
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label>{{ $t('resetPassword.field.password') }}</label>
              </div>
              <InputPassword
                v-model="model.password"
                :error="errors.password"
              />
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label>{{ $t('resetPassword.field.password2') }}</label>
              </div>
              <InputPassword
                v-model="model.password2"
                :error="errors.password2"
              />
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="onSubmit"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              <template v-else>
                {{ $t('resetPassword.submit') }}
              </template>
            </b-button>
          </b-form>

          <b-card-text class="mt-2 d-flex align-center justify-content-center">
            <feather-icon
              color="#7367F0"
              icon="ChevronLeftIcon"
              size="20"
              style="margin-left: -20px"
            />
            <BLink :to="{ name: 'sign-in' }">
              {{ $t('resetPassword.back') }}
            </BLink>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import { ref, computed } from '@vue/composition-api';
import { required, email } from '@validations';

import Logo from '@core/layouts/components/Logo.vue';
import { useRouter } from '@core/utils/utils';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import { useToast } from '@/hooks/useToast';
import InputPassword from '@/components/inputPassword/InputPassword.vue';
import { useValidation } from '@/hooks/useValidation';
import { fieldRequiredValidator, maxLengthValidator } from '@/utils/validation';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    Logo,
    InputPassword,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { welcomeToast, dangerToast } = useToast();
    const { route, router } = useRouter();
    const skin = computed(() => store.getters['appConfig/skin']);

    const model = ref({
      password: '',
      password2: '',
      email: '',
      token: '',
    });

    if (!route.value.query?.email || !route.value.query?.token) {
      router.replace({ name: 'sign-in' });
    } else {
      model.value.email = route.value.query?.email;
      model.value.token = route.value.query?.token;
    }

    const PASSWORD_MAX_LENGTH = 100;

    const [validate, errors] = useValidation([
      fieldRequiredValidator(model.value, 'password', t('errors.message.passwordRequired')),
      maxLengthValidator(
        model.value,
        'password',
        PASSWORD_MAX_LENGTH,
        t('errors.message.passwordMaxLength', { length: PASSWORD_MAX_LENGTH }),
      ),
    ]);

    const isLoading = ref(false);

    const onSubmit = async () => {
      if (isLoading.value) return;

      const isFormValid = validate();
      if (!isFormValid) return;

      isLoading.value = true;
      const { result } = await store.dispatch('user/resetPassword', {
        ...model.value,
      });
      isLoading.value = false;

      if (!result) {
        dangerToast(t('resetPassword.error'), t('resetPassword.errorBody'));
        return;
      }

      welcomeToast(t('resetPassword.success'), t('resetPassword.successBody'));
      router.push({ name: 'sign-in' });
    };

    return {
      t,

      model,
      errors,
      isLoading,
      onSubmit,
      skin,

      required,
      email,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
